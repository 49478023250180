{
  "name": "@zestymarket/wonderland-sdk",
  "version": "2.2.0",
  "description": "Wonderland Engine SDK for Zesty Banner integration.",
  "main": "dist/zesty-wonderland-sdk.js",
  "exports": {
    ".": "./dist/zesty-wonderland-sdk.js",
    "./compat": "./dist/zesty-wonderland-sdk-compat.js"
  },
  "type": "module",
  "scripts": {
    "build": "esbuild src/index.js --minify --sourcemap --format=esm --bundle --outfile=dist/zesty-wonderland-sdk.js --external:@wonderlandengine/*",
    "build-compat": "esbuild src/oldWLE.js --minify --sourcemap --bundle --outfile=dist/zesty-wonderland-sdk-compat.js",
    "build-dev": "esbuild src/index.js --sourcemap --format=esm --bundle --outfile=dist/zesty-wonderland-sdk.js --external:@wonderlandengine/*"
  },
  "wonderlandengine": {},
  "author": "",
  "license": "MIT",
  "peerDependencies": {
    "@wonderlandengine/api": "^1.0.0 || ^1.0.0-rc.5",
    "@wonderlandengine/components": "^1.0.0 || ^1.0.0-rc.6"
  },
  "devDependencies": {
    "esbuild": "^0.17.18"
  }
}
